<template>
  <div v-if="contractor">
    <PageHeader link-name="Назад" link-path="ContractorsList" page-name="Исполнитель">
      <template>
        <el-button
          v-if="contractor.state !== 'ACTIVE'"
          type="success"
          @click="approveContractor"
        >
          Подтвердить
        </el-button>
        <el-button
          v-else
          type="disabled"
        >
          Подтвержден
        </el-button>

        <el-avatar
          v-if="contractor.user.avatar"
          shape="square"
          :size="50"
          :src="contractor.user.avatar"
        />
      </template>
    </PageHeader>
    <div class="common-info">
      <div class="user-info">
        <MainPanel v-loading="loading">
          <h2>Информация о пользователе</h2>
          <table v-if="contractor" class="user-data">
            <tr>
              <td><strong>Имя</strong></td>
              <td>{{ contractor && contractor.user.show_name }}</td>
            </tr>
            <tr>
              <td><strong>ID</strong></td>
              <td>{{ contractor && contractor.user.id }}</td>
            </tr>
            <tr>
              <td><strong>Email</strong></td>
              <td>{{ contractor && contractor.user.contacts.email }}</td>
            </tr>
            <tr>
              <td><strong>Телефон</strong></td>
              <td>{{ contractor && contractor.user.contacts.phone }}</td>
            </tr>
            <tr>
              <td><strong>Был на сайте</strong></td>
              <td>{{ contractor.user && contractor.user.last_visited_at }}</td>
            </tr>
            <tr>
              <td><strong>Профиль</strong></td>
              <td>
                <button
                  class="el-button"
                  @click="onView(contractor.user.id)"
                >
                  Просмотреть профиль
                </button>
              </td>
            </tr>
          </table>
        </MainPanel>
      </div>
      <div class="contractor-info">
        <MainPanel v-loading="loading">
          <h2>Информация о исполнителе</h2>
          <table v-if="contractor" class="user-data">
            <tr>
              <td><strong>Имя</strong></td>
              <td>{{ contractor && contractor.user.show_name }}</td>
            </tr>
            <tr>
              <td><strong>ID</strong></td>
              <td>{{ contractor && contractor.id }}</td>
            </tr>
            <tr>
              <td><strong>Описание</strong></td>
              <td>{{ contractor && contractor.description }}</td>
            </tr>
            <tr>
              <td><strong>Дата создания</strong></td>
              <td>{{ contractor && contractor.created_at }}</td>
            </tr>
            <tr>
              <td><strong>Ссылка на сайт</strong></td>
              <td><a :href="contractor.frontend_url" target="_blank">{{ contractor.frontend_url }}</a></td>
            </tr>
          </table>
        </MainPanel>
      </div>
    </div>
    <div class="contractors-services">
      <MainPanel v-loading="loading">
        <h2>Услуги исполнителя</h2>
        <el-table
          :data="contractorsServices"
          style="width: 100%"
        >
          <el-table-column
            label="Название"
            prop="title"
            width="180"
          />
          <el-table-column
            label="Описание"
            prop="description"
          />
          <el-table-column
            label="Цена"
            prop="price"
            width="120"
          >
            <template slot-scope="scope">
              {{ scope.row.price !== null ? scope.row.price + ' ' + scope.row.currency : 'Договорная' }}
            </template>
          </el-table-column>
        </el-table>
      </MainPanel>
    </div>
    <div class="contractors-services">
      <MainPanel v-loading="loading">
        <h2>Портфолио</h2>
        <div class="comparing-table__label">
          Изображения
          <div v-if="portfolioImages && portfolioImages.length > 1">
            <ImageViewer
              :images="portfolioImages"
              removing-allowed
            />
          </div>
          <div v-else>
            ---
          </div>
        </div>

        <div class="comparing-table__label">
          Ссылки на видео
          <div v-if="portfolioVideos && portfolioVideos.length > 1">
            <ul>
              <li v-for="(video, index) in portfolioVideos" :key="index">
                <a :href="video" target="_blank">{{ video }}</a>
              </li>
            </ul>
          </div>
          <div v-else>
            ---
          </div>
        </div>
      </MainPanel>
    </div>
    <div class="contractors-services">
      <MainPanel v-loading="loading">
        <h2>Примеры работ</h2>
        <el-table
          :data="workExamples"
          style="width: 100%"
        >
          <el-table-column
            label="Название"
            prop="title"
            width="180"
          />
          <el-table-column
            label="Цена"
            prop="price"
            width="120"
          >
            <template slot-scope="scope">
              {{ scope.row.price !== null ? scope.row.price + ' ' + scope.row.currency : 'Договорная' }}
            </template>
          </el-table-column>
          <el-table-column
            label="Изображения"
            prop="work_example_images"
          >
            <template slot-scope="scope">
              <ImageViewer :images="scope.row.work_example_images" />
            </template>
          </el-table-column>
        </el-table>
      </MainPanel>
    </div>
    <div class="contractors-services">
      <MainPanel v-loading="loading">
        <h2>Навыки</h2>
        <div>
          <el-button
            v-for="tag in tags"
            :key="tag.id"
            style="margin: 10px 10px 0 0;"
            type="success"
          >
            {{ tag.title }}
          </el-button>
        </div>
      </MainPanel>
    </div>
  </div>
</template>

<script>
import MainPanel from '@/components/common/MainPanel'
import {mapActions} from 'vuex'
import PageHeader from '@/components/common/PageHeader';
import ImageViewer from '@/components/common/ImageViewer'


export default {
  metaInfo: {
    title: 'Исполнитель',
  },
  components: {ImageViewer, MainPanel, PageHeader},
  data() {
    return {
      contractor: null,
      contractorsServices: null,
      portfolioImages: null,
      portfolioVideos: null,
      workExamples: null,
      tags: null,
      loading: false,
      timesOfBan: [],
    }
  },
  async created() {
    this.getContractor()
  },
  methods: {
    ...mapActions('contractors', [
      'fetchContractor', 'changeContractorState',
    ]),

    async getContractor() {
      this.contractor = await this.fetchContractor(this.$route.params.id)
      this.contractorsServices = this.contractor.contractors_services
      this.portfolioImages = this.contractor.portfolio?.portfolio_images
      this.portfolioVideos = this.contractor.portfolio?.video_links
      this.workExamples = this.contractor.work_examples
      this.tags = this.contractor.tags
    },
    onView(id) {
      this.$router.push({name: 'ViewUser', params: {id}})
    },
    async approveContractor() {
      try {
        await this.changeContractorState(this.contractor.id)
        await this.$router.go(0)
        this.$message.success('Исполнитель успешно подтвержден')
      } catch (e) {
        this.$message.error(e.response.data.message)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/_variables"

.big-avatar-wrapper
  display: flex
  align-items: center


.big-avatar
  height: 90px
  width: 90px
  margin: 6px 20px 6px 6px
  border: 6px solid $color-bg
  box-shadow: 0 0 0 6px $color-text-accent-customer
  border-radius: 50%

  &.big-avatar--business
    border: 6px solid #ffffff
    box-shadow: 0 0 0 6px $color-text-accent-business

.user-data
  td
    padding: 10px 10px
    border-bottom: 1px solid #f1f1f1

  tr
    &:last-of-type
      td
        border-bottom: none

.document
  display: flex
  align-items: center
  font-size: 12px
  padding-bottom: 5px
  padding-top: 5px

.common-info
  display: flex
  align-items: flex-start

.user-info
  flex: 1
  min-width: 400px
  padding-right: 10px

.contractor-info
  flex-basis: 100%

.contractors-services
  padding-top: 10px

.comparing-table__label
  padding-top: 5px
  margin-bottom: 4px
  font-weight: 600

  i
    font-size: 20px
    margin-right: 7px
</style>
